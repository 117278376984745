import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import Card from "../components/card";
import Carousel from "../components/carousel";
import Podcast from "../components/podcast";
import Tagline from "../images/tagline.png"

const Home = ({ data, location }) => {
    let global = data.contentfulWebsite;
    
    return (
        <Layout pathname={location.pathname}>
            <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-container o-content">
                <Carousel slides={data.contentfulHomepage.carouselItems} cta={global.videoCtaText}></Carousel>
                <div className="o-wrapper o-wrapper--small o-content-spacer o-content-container u-text-center">
                    <img className="c-tagline" src={Tagline} alt={global.tagline} />
                    <div className="h2">{global.description}</div>
                </div>
                <div className="o-content-spacer o-flex o-flex--third o-flex--align-start">
                    {data.contentfulHomepage.cards.map((card, index) => 
                        <Card key={index} card={card} cta={global.videoCtaText}></Card>
                    )}
                </div>
            </div>
            {/* <div className="o-content-container">
                {data.allContentfulPodcast.nodes.map((podcast, index) => 
                    <Podcast key={index} podcast={podcast}></Podcast>
                )}
            </div> */}
        </Layout>
    );
};

export default Home

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "UNINTERRUPTED Canada"}) {
        author
        description
        title
        videoCtaText
        podcastCtaText
        tagline
        shareImage {
            file {
                url
            }
        }
    }
    contentfulHomepage(website: {title: {eq: "UNINTERRUPTED Canada"}}) {
        carouselItems {
            title
            subtitle
            image {
                resize(width: 1900) {
                    src
                }
            }
            url
        }
        cards {
            title
            subtitle
            image {
                resize(width: 600, height: 400) {
                    src
                }
            }
            url
        }
    }
    allContentfulPodcast(filter: {featured: {eq: true}}) {
        nodes {
            title
            description {
                raw
            }
            img {
                resize(width: 300) {
                    src
                }
            }
            url {
                url
                service
            }
        }
    }
}
`;
