import React from "react"
import { Link } from "gatsby"

export default class Card extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            intervalId: null,
            activeSlide: 0
        };
        this.switchSlide = this.switchSlide.bind(this);
    }

    componentDidMount() {
        let intervalId = setInterval(this.switchSlide.bind(this), 10000);
        this.setState({ intervalId: intervalId });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    switchSlide(index) {
        if(typeof index === "undefined") {
            this.setState({ activeSlide: this.state.activeSlide >= this.props.slides.length - 1 ? 0 : this.state.activeSlide + 1 });
        } else {
            clearInterval(this.state.intervalId);
            this.setState({ activeSlide: index });
        }
    }

    render() {
        return (
            <div className="c-carousel">
                <div className="c-carousel__cards">
                    {this.props.slides.map((item, index) =>
                        <div key={index} className={`c-carousel__card o-content-container  ${index === this.state.activeSlide ? 'c-carousel__card--active' : ''}`} style={{ backgroundImage: `url(${item.image.resize.src})` }}>
                            <div className="c-carousel__card-content o-content-container">
                                <div className="h4">{item.subtitle}</div>
                                <div className="h1">{item.title}</div>
                                {item.logo ? <img src={item.logo} alt={item.subtitle} /> : null}
                                <div>
                                    <Link to={item.url} className="c-button c-button--white c-button--inline">{this.props.cta}</Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="c-carousel__bullets o-content-spacer o-child-spacer o-child-spacer--small u-text-right">
                    {this.props.slides.map((item, index) =>
                        <button key={index} onClick={() => this.switchSlide(index)} className={`c-carousel__bullet ${index === this.state.activeSlide ? 'c-carousel__bullet--active' : ''}`}></button>
                    )}
                </div>
            </div>
        );
    }
};